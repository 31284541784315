import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "peinemann"
    }}>{`Peinemann`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`The Peinemann Mobilift Group is a business partner and service provider in the world of horizontal, vertical and road transport. It started as a rental company in Hoogvliet and has grown into a one-stop-shop with branches all over the world.`}</p>
    <p>{`Peinemann uses an Enterprise Resource Planning (ERP) system called `}<a parentName="p" {...{
        "href": "https://www.allsolutions.nl/"
      }}>{`AllSolutions`}</a>{`. To improve the workflow of Peinemann's customers and account managers, we've built a platform called `}<strong parentName="p">{`Mijn Peinemann`}</strong>{` which is an easy-to-understand custom frontend that transforms data coming from and going to the ERP.`}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p>{`The application, in a nutshell, serves as a `}<em parentName="p">{`data transformation`}</em>{` platform. `}<strong parentName="p">{`We do not maintain any data aside of User data and application logs`}</strong>{`. This means that all non-User related requests and responses will be sent to and received from AllSolutions.`}</p>
    <p><img alt="Logical architecture" src={require("./images/peinemann-logical-architecture.png")} /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`IDP Keycloak`}</strong>{`:`}<ul parentName="li">
          <li parentName="ul">{`Authentication platform. Stores user login data and Group data and sends emails related to account actions.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Peinemann Portal`}</strong>{`:`}<ul parentName="li">
          <li parentName="ul">{`When referring to 'Mijn Peinemann', we refer to the frontend portal the user visits when they need to modify or create data coming from AllSolutions.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Portal.Api`}</strong>{`:`}<ul parentName="li">
          <li parentName="ul">{`The .NET backend service which translates incoming and outgoing requests for the Allsolutions API. It also handles requests related to user data, in which case it talks to Keycloak.`}</li>
          <li parentName="ul">{`Emails are also sent based on user actions.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Allsolutions API`}</strong>{`:`}<ul parentName="li">
          <li parentName="ul">{`The API for AllSolutions. We don't have direct access to their database and can only retrieve data through calling their API.`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Frontend built in `}<strong parentName="li">{`React 17.0.2`}</strong></li>
      <li parentName="ul">{`Portal API build in `}<strong parentName="li">{`.NET Core (6.0)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Swagger docs`}</strong>{` are included for the Portal and available for all environments`}</li>
      <li parentName="ul"><strong parentName="li">{`Docker & helm`}</strong>{` charts`}</li>
      <li parentName="ul">{`Uses `}<strong parentName="li">{`GitVersion`}</strong>{` to auto-version artifacts`}</li>
      <li parentName="ul"><strong parentName="li">{`Azure DevOps`}</strong>{` pipeline definitions`}</li>
      <li parentName="ul"><strong parentName="li">{`Keycloak`}</strong>{` as OAuth/OpenID server`}</li>
      <li parentName="ul"><strong parentName="li">{`PostgreSQL`}</strong>{` for Portal API and OAuth/OpenID server`}</li>
      <li parentName="ul">{`Uses `}<strong parentName="li">{`git secret`}</strong>{` to encrypt sensitive information like configuration files`}</li>
      <li parentName="ul">{`Terraform to setup all (cloud) infrastructure`}</li>
      <li parentName="ul">{`External AllSolutions API of Peinemann to access client data`}</li>
      <li parentName="ul">{`External Mailgun subscription of Peinemann for emails`}</li>
    </ul>
    <h2 {...{
      "id": "requirements"
    }}>{`Requirements`}</h2>
    <h3 {...{
      "id": "general-requirements"
    }}>{`General requirements`}</h3>
    <ul>
      <li parentName="ul">{`Ensure you are added to the required repositories (see below).`}</li>
      <li parentName="ul">{`Ensure you are part of the git keyring and able to run `}<inlineCode parentName="li">{`git secret reveal`}</inlineCode>{`. You can see who is able to add you by running `}<inlineCode parentName="li">{`git secret whoknows`}</inlineCode>{`. You can't run any of the projects without access as you'll be missing certain credentials.`}</li>
    </ul>
    <h3 {...{
      "id": "project-requirements"
    }}>{`Project requirements`}</h3>
    <p><strong parentName="p">{`Please read the project README's`}</strong>{`. They'll help you get started and may also provide additional insights and tips on code quality and maintenance.`}</p>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Url`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal frontend`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mijn Peinemann React frontend.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/peinemann-portal-react"
            }}>{`https://github.com/HybrIT-dev/peinemann-portal-react`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal API`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Peinemann Portal API for the portal and connection with PowerBI.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/peinemann-portal-api-dotnet"
            }}>{`https://github.com/HybrIT-dev/peinemann-portal-api-dotnet`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Implementation of Keycloak with specific Peinemann theme. This repository also holds the identity API (abstraction over keycloak for users/clients) and the helm charts needed to deploy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/peinemann-portal-keycloak"
            }}>{`https://github.com/HybrIT-dev/peinemann-portal-keycloak`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Infrastructure`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The infrastructure (terraform) used to roll out all cloud resources needed to run.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/peinemann-portal-infrastructure"
            }}>{`https://github.com/HybrIT-dev/peinemann-portal-infrastructure`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "endpoints"
    }}>{`Endpoints`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Visiting any of the API endpoints directly will redirect you to `}<inlineCode parentName="p">{`/swagger/index.html`}</inlineCode>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`With the exception of Local, any environment's build can be updated by running the pipeline with the chosen version in `}<a parentName="p" {...{
          "href": "https://dev.azure.com/hybritdev/Peinemann/"
        }}>{`Azure DevOps`}</a>{`.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Test`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Production`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://portal.test.peinemann.hybrit.dev"
            }}>{`https://portal.test.peinemann.hybrit.dev`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://peinemann.hybrit.dev"
            }}>{`https://peinemann.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`API`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://api.test.peinemann.hybrit.dev"
            }}>{`https://api.test.peinemann.hybrit.dev`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://api.peinemann.hybrit.dev"
            }}>{`https://api.peinemann.hybrit.dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://auth.test.peinemann.hybrit.dev/auth"
            }}>{`https://auth.test.peinemann.hybrit.dev/auth`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://auth.peinemann.hybrit.dev/auth"
            }}>{`https://auth.peinemann.hybrit.dev/auth`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AllSolutions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://acceptatie-peinemann.allsolutions.nl/api/v1/"
            }}>{`https://acceptatie-peinemann.allsolutions.nl/api/v1/`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://peinemann.allsolutions.nl/api/v1/"
            }}>{`https://peinemann.allsolutions.nl/api/v1/`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "authentication-and-authorization"
    }}>{`Authentication and authorization`}</h2>
    <h3 {...{
      "id": "keycloak-groups-and-clients"
    }}>{`Keycloak groups and clients`}</h3>
    <blockquote>
      <p parentName="blockquote">{`All frontend and backend services connect to an instance of Keycloak for user authentication.`}</p>
    </blockquote>
    <p>{`All non-admin users are assigned a group in Keycloak. These groups are all a subgroup of `}<inlineCode parentName="p">{`/debnr/`}</inlineCode>{` and generated based on the relation id chosen for the user.`}</p>
    <p>{`Keycloak has a `}<inlineCode parentName="p">{`dotnet-api`}</inlineCode>{` client group which serves as the platform's role management system for frontend and backend authentication.`}</p>
    <h3 {...{
      "id": "roles"
    }}>{`Roles`}</h3>
    <p>{`The following hierarchy is used for role-based authentication:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Keycloak role`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`API role`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`User`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default users. They lack a role in Keycloak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`primary_user`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PrimaryUser`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can create new users in Mijn Peinemann`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account_manager`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AccountManager`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can perform offer-related actions in Mijn Peinemann`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`admin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Admin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can access everything`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "policies"
    }}>{`Policies`}</h3>
    <p>{`The backend contains the following policies for the API calls:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Policy`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Autentication criteria`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`User`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User must be authenticated (default check for all policies).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CanAccessUser`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User must be in correct role and organisation to view or edit user with id `}<inlineCode parentName="td">{`userId`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CanListAndCreateUsers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User must have role `}<inlineCode parentName="td">{`PrimaryUser`}</inlineCode>{` or `}<inlineCode parentName="td">{`Admin`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CanManageOffer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User must have role `}<inlineCode parentName="td">{`AccountManager`}</inlineCode>{` or `}<inlineCode parentName="td">{`Admin`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CanApproveOffer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User must NOT have role `}<inlineCode parentName="td">{`AccountManager`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "authorization"
    }}>{`Authorization`}</h3>
    <p>{`The following actions require authorization of some kind:`}</p>
    <h4 {...{
      "id": "user-management"
    }}>{`User management`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`User`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Primary user`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Account manager`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Admin`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View list of users`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non-admins in organisation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Self only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non-admins in organisation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Self only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update user name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Self only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only non-admins`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Self only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update user role`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes(2)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update user organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`(1) Primary users can change the role of Users and Primary Users. They cannot change or select the role of Account Managers or Admins.`}</p>
    <p>{`(2) Admins cannot demote themselves or other Admins.`}</p>
    <h4 {...{
      "id": "offer-management"
    }}>{`Offer management`}</h4>
    <ul>
      <li parentName="ul">{`Peinemann refers to offers as Werkopnames.`}</li>
      <li parentName="ul">{`Werkopnames become `}<em parentName="li">{`Huuropdrachten`}</em>{` once they are approved by the user and have the `}<inlineCode parentName="li">{`OfferType`}</inlineCode>{` of `}<inlineCode parentName="li">{`Regular`}</inlineCode>{`. If not approved yet, they have the type `}<inlineCode parentName="li">{`Intake`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`API-wise, they will always remain `}<inlineCode parentName="li">{`offers`}</inlineCode>{`.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`User`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Primary user`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Account manager`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Admin`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View list of werkopnames`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Verify werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Approve werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reject werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Book out active machine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Complete werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove machine from werkopname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`(1) Whilst technically the same call, account managers can cancel non-approved werkopnames only during the completion process. Other users can only do so during verification.`}</p>
    <h4 {...{
      "id": "other-frontend-authorization-actions"
    }}>{`Other frontend authorization actions`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`User`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Primary user`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Account manager`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Admin`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Place an order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View and receive notifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`(1) The response data is different. Account managers receive the number of intakes to be completed. Other roles receive the number of completed intakes, past due invoices and current active orders.`}</p>
    <h2 {...{
      "id": "challenges"
    }}>{`Challenges`}</h2>
    <h3 {...{
      "id": "allsolutions-data-filtering--swagger"
    }}>{`AllSolutions data filtering + swagger`}</h3>
    <p>{`Certain endpoints allow us to apply filters in order to more specificially query the data we receive from AllSolutions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://acceptatie-peinemann.allsolutions.nl/api/swagger/?url=https://acceptatie-peinemann.allsolutions.nl/webfolder/swagger/mijnpeinemann.yaml"
      }}>{`The swagger documentation of AllSolutions`}</a>{` is complicated and in some cases incomplete. Whenever we must build a request with a filter using something like `}<inlineCode parentName="p">{`$filter-freeform`}</inlineCode>{`, this may not be documented anywhere in the swagger. It may also occur that you write a filter that is correct (for example, `}<inlineCode parentName="p">{`$Actief`}</inlineCode>{` on `}<inlineCode parentName="p">{`/mrlorg`}</inlineCode>{`) but AllSolutions can't understand the request and throws a 400.`}</p>
    <p>{`Certain extended urls are also hidden in the documentation. For example, if we wish to get a certain document for an order, calling `}<inlineCode parentName="p">{`/morder/{id1}/binder`}</inlineCode>{` is not enough. Opening this call in the swagger of AllSolutions will tell you that you need to both add the document's id `}<strong parentName="p">{`and`}</strong>{` specify an action. In our case, we'd have to append `}<inlineCode parentName="p">{`/{id}/lezen`}</inlineCode>{` to our url.`}</p>
    <p><em parentName="p">{`This isn't immediately obvious`}</em>{`, so keep that in mind.`}</p>
    <p>{`If you get stuck and don't know how to fix it, get in touch with Peinemann. They can relay the request to AllSolutions.`}</p>
    <h3 {...{
      "id": "performance-of-our-api"
    }}>{`Performance of our API`}</h3>
    <p><strong parentName="p">{`The response time of our API is limited to the response time of the API of AllSolutions`}</strong>{`. We have no backdoor to the database of AllSolutions to write our own optimized queries. This means that, if data doesn't show up in an acceptable time, the root cause `}<em parentName="p">{`usually`}</em>{` can be found within the system that is AllSolutions.`}</p>
    <p>{`What we `}<strong parentName="p">{`can`}</strong>{` do to improve performance, however, is reducing the amount of calls we have to make. The frontend has partial caching, for example, which means it won't have to re-request data if it already has fetched a collection that matches the earlier criteria. The backend also re-uses certain id's to save a call or two.`}</p>
    <p>{`We currently do not save data other than logging and OAuth data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      